import axios from "axios";

export const adminApiUrl = axios.create({
    // baseURL: 'http://localhost:8080/bigshot-nutrition/'
    baseURL: 'https://onenutrition.excellencescholar.com/'
    // baseURL: 'https://www.bigshotnutrition.com/'
   
});

export const logoutapi = async (data) => {
    let logoutresponse = await adminApiUrl.post(`logout`);
    console.log(logoutresponse)
}
export const deletSilderItem = async (imgID, access_token) => {
    console.log("seletectedData", imgID)
    await adminApiUrl.delete(`api/slider/${imgID}`, {
        headers: {
            'Authorization': access_token,
        },
    }).then(res => {
        window.location.reload()
    }).catch(err => {
        console.log(err)
    });
}
export const getSlideritems = async (Profile) => {
    let response = await adminApiUrl.get(`api/slider`, {
        headers: {
            'Authorization': Profile.access_token,
        },
    });
    return response;
}
export const getAdminLogin = (form, callback) => {
    adminApiUrl.post(`login`, form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then(res => {
            console.log(res)
            if (res.data.message == "Login success!") {
                callback(res);
                localStorage.setItem("logindata", JSON.stringify(res.data));
            } else {
                alert("Invalid credentials!!");
            }
        }).catch(err => {
            console.log(err)
        })
}
export const addUpdateSlider = (sliderData, Profile) => {
    console.log("sliderData", sliderData)
    console.log("Profile", Profile)

    adminApiUrl.post(`api/slider/`, sliderData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": Profile.access_token,
        },
    })
        .then(res => {
            console.log(res)
            alert(res.data.message)
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
}
export const getCategoriesitems = async (Profile) => {
    let response = await adminApiUrl.get(`api/categories/`, {
        headers: {
            'Authorization': Profile.access_token,
        },
    });
    return response;
}
export const addUpdateCategories = (cateData, Profile) => {
    console.log("cateData", cateData)
    console.log("Profile", Profile)

    adminApiUrl.post(`api/categories/`, cateData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": Profile.access_token,
        },
    })
        .then(res => {
            alert(res.data.message)
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
}
export const deletCateItem = async (catID, access_token) => {
    console.log("seletectedData", catID)
    await adminApiUrl.delete(`api/categories/${catID}`, {
        headers: {
            'Authorization': access_token,
        },
    }).then(res => {
        window.location.reload()
        console.log(res)
    }).catch(err => {
        console.log(err)
    });
}

/* Product API */
export const getProducts = async (Profile) => {
    let response = await adminApiUrl.get(`api/products/`, {
        headers: {
            'Authorization': Profile.access_token,
        },
    });
    return response;
}
export const deletProduct = async (productID, access_token) => {
    console.log("seletectedData", productID)
    await adminApiUrl.delete(`api/products/${productID}`, {
        headers: {
            'Authorization': access_token,
        },
    }).then(res => {
        console.log(res.data.message);
        window.location.reload()
    }).catch(err => {
        console.log(err)
    });
}
export const addUpdateProduct = (productData, Profile) => {
    adminApiUrl.post(`api/products/`, productData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": Profile.access_token,
        },
    })
    .then(res => {
        console.log(res.data.message)
        alert(res.data.message);
        window.location.reload()
    }).catch(err => {
        console.log(err)
    })
}
//about api
export const getAbout = async (Profile) => {
    let response = await adminApiUrl.get(`api/aboutus`, {
        headers: {
            'Authorization': Profile.access_token,
        },
    });
    return response;
}
export const deleteAbout = async (productID, access_token) => {
    console.log("seletectedData", productID)
    await adminApiUrl.delete(`api/aboutus/${productID}`, {
        headers: {
            'Authorization': access_token,
        },
    }).then(res => {
        console.log(res.data.message);
        window.location.reload()
    }).catch(err => {
        console.log(err)
    });
}
export const addUpdateAbout = (productData, Profile) => {
    adminApiUrl.post(`api/aboutus/`, productData, { 
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": Profile.access_token,
        },
    })
    .then(res => {
        console.log(res.data.message)
        alert(res.data.message);
        window.location.reload()
    }).catch(err => {
        console.log(err)
    })
}
//Testimonials api
export const getTestimonials = async (Profile) => {
    let response = await adminApiUrl.get(`api/testimonials`, {
        headers: {
            'Authorization': Profile.access_token,
        },
    });
    return response;
}
export const deleteTestimonials = async (productID, access_token) => {
    console.log("seletectedData", productID)
    await adminApiUrl.delete(`api/testimonials/${productID}`, {
        headers: {
            'Authorization': access_token,
        },
    }).then(res => {
        console.log(res.data.message);
        window.location.reload()
    }).catch(err => {
        console.log(err)
    });
}
export const addUpdateTestimonials = (productData, Profile) => {
    adminApiUrl.post(`api/testimonials/`, productData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": Profile.access_token,
        },
    })
    .then(res => {
        console.log(res.data.message)
        alert(res.data.message);
        window.location.reload()
    }).catch(err => {
        console.log(err)
    })
}
